import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'antd';
import Header from '@/views/header';
import Footer from '@/views/footer';
import oneImg from '@/assets/home/one.png';
import twoImg from '@/assets/home/two.png';
import threeImg from '@/assets/home/three.png';
import fourImg from '@/assets/home/four.png';
import fiveImg from '@/assets/home/five.png';
import logoWhiteImg from '@/assets/home/logo-white.png';
import './index.css';

const Home = () => {
  const navigate = useNavigate();
  const homeTwoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    document.title = '鸿凯瑞达-首页';
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      window.location.replace('/m/home');
    }

    const { top } = homeTwoRef.current.getBoundingClientRect();
    setTwoTopHeight(top);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;

      if (scrollDistance >= twoTopHeight) {
        // 滚动距离达到了指定值，设置状态以触发动画
        setAnimate(true);
      } else {
        // 如果滚动距离小于阈值，重置状态
        setAnimate(false);
      }
    };

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const goReultPage = () => {
    navigate('/result');
  };
  return (
    <div>
      <div className="home-one">
        <Header pageName="home" />
        <Carousel effect="fade" className="cc" autoplay autoplaySpeed={2000}>
          <div className="carousel-img">
            <div className="left-ctn">
              <div className="title">智能无线报警施封锁</div>
              <div className="ctn">可复用、安全性极大提升的施封锁技术</div>
              <div className="btn" onClick={() => goReultPage()}>
                了解详情
              </div>
            </div>
          </div>
          <div className="carousel-img">
            <div className="left-ctn">
              <div className="title">智能无线报警施封锁</div>
              <div className="ctn">可复用、安全性极大提升的施封锁技术</div>
              <div className="btn" onClick={() => goReultPage()}>
                了解详情
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className="home-two">
        <div
          className={`title ${
            animate ? 'animate__animated animate__bounceIn' : ''
          }`}
        >
          智能、安全、稳定的产品及服务
        </div>
        <div className="look-all" onClick={() => goReultPage()}>
          查看全部产品
        </div>
        <div className="flex-row" ref={homeTwoRef}>
          <div
            className={`left-card ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
          >
            <div className="card-title">智能应用</div>
            <div className="card-ctn">
              将实物与智能应用结合，物联网技术 让管理更高效、更安全
            </div>
            <img src={oneImg} alt="" className="card-img" />
          </div>
          <div
            className={`${
              animate ? 'animate__animated animate__backInRight' : ''
            }`}
          >
            <div className="left-right ">
              <div className="card-title">安全保障</div>
              <div className="card-ctn">智能电子锁，实时监管报警信息</div>
              <img src={twoImg} alt="" className="card-img" />
            </div>
            <div className="left-right">
              <div className="card-title">多种场景</div>
              <div className="card-ctn">
                多种应用场景，货物运输、库房仓储反复使用 减少成本，环保方便
              </div>
              <img src={threeImg} alt="" className="card-img" />
            </div>
          </div>
          <div
            className={`${
              animate ? 'animate__animated animate__backInRight' : ''
            }`}
          >
            <div className="left-right">
              <div className="card-title">灵活敏捷</div>
              <div className="card-ctn">减少管理成本，智能管理一体化</div>
              <img src={fourImg} alt="" className="card-img" />
            </div>
            <div className="left-right">
              <div className="card-title">专注专业</div>
              <div className="card-ctn">成熟化体系，让用户放心托付</div>
              <img src={fiveImg} alt="" className="card-img" />
            </div>
          </div>
        </div>
      </div>

      <div className="bt-ctn">
        <img src={logoWhiteImg} alt="" className="logo-white" />
        <div>致力于成为最具竞争力的智能电子企业</div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
