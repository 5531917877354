import React, { useEffect, useRef, useState } from 'react';
import Header from '@/views/header';
import Footer from '@/views/footer';
import logoImg from '@/assets/home/HKRD@2x.png';

import './index.css';

const Aboutus = () => {
  const homeTwoRef = useRef(null);
  const logoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [logoTopHeight, setLogoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [logoAnimate, setLogoAnimate] = useState(false);

  useEffect(() => {
    document.title = '鸿凯瑞达-关于我们';
    const { top } = homeTwoRef.current.getBoundingClientRect();
    setTwoTopHeight(top);
    const logoRefValus = logoRef.current.getBoundingClientRect();
    setLogoTopHeight(logoRefValus.top);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;

      if (scrollDistance >= twoTopHeight) {
        // 滚动距离达到了指定值，设置状态以触发动画
        setAnimate(true);
      } else {
        // 如果滚动距离小于阈值，重置状态
        setAnimate(false);
      }

      if (scrollDistance >= logoTopHeight) {
        setLogoAnimate(true);
      }
    };

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="home-one">
        <Header pageName="aboutus" />
        <div className="about-bg-top-img">
          <div className="img-text">关于我们</div>
        </div>
      </div>
      <div className="home-two-about">
        <div className="img-ctn">
          <div className="title">公司简介</div>
          <div className="right-ctn" ref={homeTwoRef}>
            <div
              className={`ctn ${
                animate ? 'animate__animated animate__backInRight' : ''
              }`}
            >
              西安鸿凯瑞达智能电子科技有限公司致力于智能电子产品的研发，已开发了多款基于NB/LORA/4G的智能电子产品，涵盖了智能设备、物联网等多个领域。
            </div>

            <div
              className={`ctn ${
                animate ? 'animate__animated animate__backInRight' : ''
              }`}
            >
              一直专注国内外信息技术的发展动态、新技术的研究和全面科学的开发管理体制的建设，在信息技术产品及其模块的软硬件技术的研究、技术解析、核心技术的研究以及反向研究等方面有很多突破。
            </div>

            <div
              className={`ctn ${
                animate ? 'animate__animated animate__backInRight' : ''
              }`}
            >
              我们致力于利用先进的技术和创新的思维，为客户提供高品质、高性能的产品和解决方案。
            </div>
          </div>
        </div>
      </div>
      <div className="about-three-about">
        <div className="img-ctn">
          <div className="title">企业文化</div>
        </div>
        <img
          src={logoImg}
          className={`logoimg ${
            logoAnimate ? 'animate__animated animate__backInLeft' : ''
          }`}
          alt=""
        />
        <div
          ref={logoRef}
          className={`logo-text ${
            logoAnimate ? 'animate__animated animate__backInRight' : ''
          }`}
        >
          客户至上、求真务实、创新进取、追求卓越
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
