import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@/views/m/header';
import Footer from '@/views/m/footer';
import DownloadBtn from '@/views/m/downloadBtn';
import logoImg from '@/assets/m/icon@2x.png';

import './index.css';

const Reult = () => {
  const homeTwoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(true);
  const navigate = useNavigate();
  const goDownloadPage = () => {
    navigate('/m/downloadpage');
  };

  useEffect(() => {
    document.title = '鸿凯瑞达-产品';
    const { top } = homeTwoRef.current.getBoundingClientRect();
    setTwoTopHeight(top);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;

      if (scrollDistance >= twoTopHeight && !animate) {
        // 滚动距离达到了指定值，设置状态以触发动画
        setAnimate(true);
      } else {
        // 如果滚动距离小于阈值，重置状态
        setAnimate(false);
      }
    };

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="home-one">
        <Header pageName="result" />
        <div className="m-bg-top-img">
          <div className="img-text">无线报警施封锁</div>
        </div>
      </div>
      <div className="m-home-two-result">
        <div className="img-ctn" ref={homeTwoRef}>
          <div
            className={`title ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
          >
            鸿凯瑞达智能电子施封锁
          </div>
          <div
            className={`ctn ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
          >
            无线报警智能施封锁是一款具有智能报警且可反复使用的施封锁，他解决了传统施封锁不能重复使用，不能及时获知锁具的状态，非法损坏不能及时知晓，无法进行多级授权开锁，无法确认操作人身份，工作过程无法跟踪等弊端。
          </div>
          <div
            className={`${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
            style={{ marginTop: 3 }}
          >
            智能报警施封锁能够智能检测锁绳剪断、锁体受到破坏、锁具温度过高并且立即发送报警信息给控制中心/锁具管理人员，使管理人员可在第一时间发现并采取防范措施，避免非法开启带来的经济损失和管理风险。
          </div>
        </div>
        <div
          className={`download-btn ${
            animate ? 'animate__animated animate__backInLeft' : ''
          }`}
        >
          <img src={logoImg} alt="" className="logoimg" />
          <div className="down-btn" onClick={() => goDownloadPage()}>
            立即下载
          </div>
        </div>
      </div>
      <DownloadBtn />
      <Footer />
    </div>
  );
};

export default Reult;
