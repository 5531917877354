import React from 'react';
import './index.css';

const Header = () => {
  return (
    <div className="m-bttom-ctn">
      <div>版权所有®西安鸿凯瑞达智能电子科技有限公司</div>
      <div style={{ marginTop: 8 }}>TEL：029-85223533 17791360109</div>
      <div>地址：陕西省西安市曲江新区雁展路6号曲江会展国际E栋701室</div>
      <div
        style={{ marginBottom: 0, cursor: 'pointer' }}
        onClick={() => window.open('https://beian.miit.gov.cn/')}
      >
        陕ICP备2024027996号-1
      </div>
    </div>
  );
};

export default Header;
