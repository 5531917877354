import React, { useEffect, useRef, useState } from 'react';
import Header from '@/views/header';
import Footer from '@/views/footer';

import './index.css';

const Reult = () => {
  const homeTwoRef = useRef(null);
  const homeThreeRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [threeTopHeight, setThreeTopHeight] = useState(0);
  const [animate, setAnimate] = useState(true);
  const [threeAnimate, setThreeAnimate] = useState(false);
  useEffect(() => {
    document.title = '鸿凯瑞达-产品';
    const { top } = homeTwoRef.current.getBoundingClientRect();
    setTwoTopHeight(top);
    const threeRef = homeTwoRef.current.getBoundingClientRect();
    setThreeTopHeight(threeRef.top);
  }, []);

  // useEffect(() => {
  //   const handleScrollThree = () => {
  //     const scrollDistance = window.scrollY;

  //     if (scrollDistance >= threeTopHeight) {
  //       // 滚动距离达到了指定值，设置状态以触发动画
  //       setThreeAnimate(true);
  //     } else {
  //       // 如果滚动距离小于阈值，重置状态
  //       setThreeAnimate(false);
  //     }
  //   };

  //   // 添加滚动事件监听器
  //   window.addEventListener('scroll', handleScrollThree);

  //   // 组件卸载时移除监听器
  //   return () => {
  //     window.removeEventListener('scroll', handleScrollThree);
  //   };
  // }, []);

  return (
    <div>
      <div className="home-one">
        <Header pageName="result" />
        <div className="bg-top-img">
          <div className="img-text">无线报警施封锁</div>
        </div>
      </div>
      <div className="home-two-result">
        <div className="img-ctn" ref={homeTwoRef}>
          <div
            className={`title ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
          >
            鸿凯瑞达智能电子施封锁
          </div>
          <div
            className={`ctn ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
            style={{ width: 765 }}
          >
            无线报警智能施封锁是一款具有智能报警且可反复使用的施封锁，他解决了传统施封锁不能重复使用，不能及时获知锁具的状态，非法损坏不能及时知晓，无法进行多级授权开锁，无法确认操作人身份，工作过程无法跟踪等弊端。
          </div>
          <div
            className={`ctn ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
            style={{ width: 765 }}
          >
            智能报警施封锁能够智能检测锁绳剪断、锁体受到破坏、锁具温度过高并且立即发送报警信息给控制中心/锁具管理人员，使管理人员可在第一时间发现并采取防范措施，避免非法开启带来的经济损失和管理风险。
          </div>
        </div>
      </div>
      {/* <div className="home-three-result">
        <div className="img-ctn" ref={homeTwoRef}>
          <div
            className={`title ${
              animate ? 'animate__animated animate__backInLeft' : ''
            }`}
          >
            方案技术简介
          </div>
          <div
            className={`ctn ${
              threeAnimate ? 'animate__animated animate__backInLeft' : ''
            }`}
            style={{ width: 765 }}
            ref={homeThreeRef}
          >
            针对原有的施封锁功能，进行了技术革新。将报警与可复用技术运用在新型施封锁中，提高了安全性和环保性；通过蓝牙
            与NB技术，让施封锁信息可以实时推送，节省管理成本。
          </div>
        </div>
      </div> */}
      {/* <div className="home-four-result">
        <div className="left-ctn-four"></div>
        <div className="right-ctn-four">
          <div className="title">无限报警施封锁</div>
          <div className="title-line"></div>
          <div className="ctn">
            施封锁管理APP是一款用于管理施封锁产品的移动应用程序。
          </div>
          <div className="ctn" style={{ marginTop: 3 }}>
            其主要功能和特点包括：施封锁区域化管理、报警信息接收、人员和设备的管理等多元化管理。
          </div>

          <div className="right-card">
            <div>
              <div><span>产品材质</span></div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Reult;
